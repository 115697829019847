import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import { useSelector, useDispatch } from 'react-redux';
import { copyFromPreviousSite } from '../../../../store/actions/createSiteActions';
import DeliveryModeModal from '../modals/DeliveryMode';

export default function CopyPrevSite(props) {
  const dispatch = useDispatch();
  const sisCourseId = useSelector((state) => state.createSite.sisCourseId);

  const columns = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <Radio checked={sisCourseId === params.id} value={params.id} />
      ),
    },
    {
      field: 'seamsCourseName',
      headerName: 'SEAMS ID and Course name',
      width: 500,
      sortable: false,
    },
    {
      field: 'year',
      headerName: 'Year',
      width: 130,
      sortable: false,
    },
    {
      field: 'session',
      headerName: 'Session',
      width: 130,
      sortable: false,
    },
    {
      field: 'uos',
      headerName: 'Units of study',
      width: 130,
      sortable: false,
    },
    {
      field: 'deliveryMode',
      headerName: <DeliveryModeModal />,
      width: 150,
      sortable: false,
    },
  ];

  const transformedRows = props.rows.map((row) => ({
    id: row.canvas.sisId,
    seamsCourseName: row.name,
    year: row.session.year,
    session: row.session.period,
    uos: row.id,
    deliveryMode: row.deliverymode,
  }));

  const copyUoSHandler = (newSelectionModel) => {
      if (newSelectionModel[0]) {
      dispatch(copyFromPreviousSite(newSelectionModel[0]));
    }
  };

  return (
    <div className='App'>
      <DataGrid
        rows={transformedRows}
        columns={columns}
        autoHeight
        selectionModel={sisCourseId ? [sisCourseId] : []}
        onSelectionModelChange={copyUoSHandler}
      />
    </div>
  );
}
