export const PERMISSIONS_DROPDOWN = [
  { value: 0, label: 'none' },
  { value: 2, label: 'R' },
  { value: 6, label: 'RU' },
  { value: 3, label: 'CR' },
  { value: 7, label: 'CRU' },
  { value: 15, label: 'CRUD' },
];

export const ROLES = [
  {
    ROLEID: 'facsup',
    GROUPTYPE: 'faculty',
    DESC_SHORT: 'Faculty Support',
    DESC_LONG: '',
    DORDER: 30,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'none',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'none',
    },
  },
  {
    ROLEID: 'eamadm',
    GROUPTYPE: 'eam',
    DESC_SHORT: 'EAM Admin',
    DESC_LONG: '',
    DORDER: 0,
    PERMISSIONS: {
      eam_admin: 'CRUD',
      non_students: 'CRUD',
      sections: 'CRUD',
      sis_data: 'CRUD',
      cat_cou_ter: 'CRUD',
      reports: 'CRUD',
      eb_ce6: 'CRUD',
      cal: 'CRUD',
    },
  },
  {
    ROLEID: 'CAL',
    GROUPTYPE: 'eam',
    DESC_SHORT: 'Copyright User',
    DESC_LONG: '',
    DORDER: 4,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'R',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'CRUD',
    },
  },
  {
    ROLEID: 'helpdesk',
    GROUPTYPE: 'eam',
    DESC_SHORT: 'Help Desk User',
    DESC_LONG: 'Role for helpdesk staff',
    DORDER: 2,
    PERMISSIONS: {
      eam_admin: 'CRUD',
      non_students: 'CRUD',
      sections: 'CRUD',
      sis_data: 'R',
      cat_cou_ter: 'R',
      reports: 'R',
      eb_ce6: 'R',
      cal: 'R',
    },
  },
  {
    ROLEID: 'facexa',
    GROUPTYPE: 'faculty',
    DESC_SHORT: 'Exam Faculty Admin',
    DESC_LONG: '',
    DORDER: 50,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'none',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'none',
    },
  },
  {
    ROLEID: 'facadm',
    GROUPTYPE: 'faculty',
    DESC_SHORT: 'Faculty adm',
    DESC_LONG: '',
    DORDER: 40,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'none',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'none',
    },
  },
  {
    ROLEID: 'facAdmin',
    GROUPTYPE: 'faculty',
    DESC_SHORT: 'Faculty Admin',
    DESC_LONG: '',
    DORDER: 10,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'none',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'none',
    },
  },
  {
    ROLEID: 'foltadm',
    GROUPTYPE: 'eam',
    DESC_SHORT: 'FOLT Admins',
    DESC_LONG: '',
    DORDER: 1,
    PERMISSIONS: {
      eam_admin: 'none',
      non_students: 'none',
      sections: 'none',
      sis_data: 'none',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'none',
    },
  },
  {
    ROLEID: 'A2A',
    GROUPTYPE: 'eam',
    DESC_SHORT: 'A2A User',
    DESC_LONG: 'Role for A2A User',
    DORDER: 3,
    PERMISSIONS: {
      eam_admin: 'CRUD',
      non_students: 'CRUD',
      sections: 'CRUD',
      sis_data: 'R',
      cat_cou_ter: 'none',
      reports: 'none',
      eb_ce6: 'none',
      cal: 'R',
    },
  },
];

export const SORTED_ROLES = ROLES.sort((a, b) => a.DORDER - b.DORDER);
