import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { createSiteActions } from '../../store/reducers/createSiteReducer';

export default function DatePickerStart() {
  const dispatch = useDispatch();
  const startTime = useSelector((state) => state.createSite.startTime);

  return (
    <div>
      <DatePicker
        selected={new Date(startTime)}
        onChange={(date) =>
          dispatch(createSiteActions.setStartTime(date.toISOString()))
        }
        dateFormat='eeee MMMM d, yyyy'
        className='datePicker'
      />
    </div>
  );
}
