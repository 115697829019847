import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DatePickerStart from '../../DatePickerStart';
import DatePickerEnd from '../../DatePickerEnd';

const Step4 = () => {
  return (
    <Box>
      <Grid container>
        <Grid item md={12} lg={12} xl={12}>
          <Grid container direction='row' alignItems='center'>
            <Grid item md={12} lg={12} xl={12} sx={{ mb: 2 }}>
              <Typography variant='h3' className='tabH3'>
                Review student access dates to your new Canvas site
              </Typography>
            </Grid>
            <Grid item md={3} lg={3} xl={3} sx={{ mr: 3 }}>
              <span className='datePickerSubtitle'>
                Start date - students gain access
              </span>
              <DatePickerStart />
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <span className='datePickerSubtitle'>
                End date - site becomes view-only
              </span>
              <DatePickerEnd />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step4;
