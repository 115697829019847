import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import CopyPrevSiteTable from '../tables/CopyPrevSite';

import { copyContentOptions } from '../../../../config/canvas';
import { createSiteActions } from '../../../../store/reducers/createSiteReducer';
import { getPrevSites } from '../../../../store/actions/createSiteActions';
import Spinner from '../../../layout/Spinner';

const Step2 = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.createSite.loading);
  const selectedUoS = useSelector((state) => state.createSite.selectedUoS);
  const copyContent = useSelector((state) => state.createSite.copyContent);
  const prevUoSSites = useSelector((state) => state.createSite.prevUoSSites);

  const copyContentHandler = (option) => {
    dispatch(createSiteActions.setCopyContent(option));
    if (option === 'copy-prev-site') {
      dispatch(getPrevSites(selectedUoS));
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item md={12} lg={12} xl={12}>
          <FormControl>
            <Grid container direction='row' alignItems='center'>
              <Grid item>
                <Typography variant='h3' className='tabH3'>
                  How would you like to add content to your Canvas site?
                </Typography>
              </Grid>
            </Grid>
            <RadioGroup
              aria-labelledby='how-will-your-new-canvas-site-be-used-group-label'
              name='radio-buttons-group'
            >
              {copyContentOptions.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  checked={copyContent === option.value}
                  onClick={() => copyContentHandler(option.value)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        {loading && <Spinner />}
        {!loading && (
          <React.Suspense fallback={<CircularProgress />}>
            <Grid item md={12} lg={12} xl={12} sx={{ mt: 3 }}>
              {copyContent === 'copy-prev-site' && (
                <CopyPrevSiteTable rows={prevUoSSites} />
              )}
              {/* copyContent === 'faculty-template' && <FacultyTemplateTable />*/}
            </Grid>
          </React.Suspense>
        )}
      </Grid>
    </Box>
  );
};

export default Step2;
