import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSiteActions } from '../../../../store/reducers/createSiteReducer'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddStaffTable from '../tables/AddStaff';

const Step3 = () => {
  const {staffList, selectedStaff, selectedUoSObject } = useSelector((state) => state.createSite);
  const auth = useSelector(s=>s.auth)
  const dispatch = useDispatch()

  const staff = [], selected = []
  if (!selectedStaff.length) {
    selected.push(auth.unikey)
    staff.splice(0, 0, {
      unikey: auth.unikey,
      familyName: auth.familyname,
      givenNames: auth.givenname,
      canvas: auth.canvas,
      role: "instructor",
      isPrimary: false,
    })
    if (selectedUoSObject.coordinator) {
      selected.splice(0, 0, selectedUoSObject.coordinator.unikey)
      staff.splice(0, 0, {
        ...selectedUoSObject.coordinator,
        role: "instructor",
        isPrimary: true,
        isCoordinator: true,
      })
    }
    selectedStaff.forEach(s=>{
      if (!selected.find(ss => ss === s))
        selected.push(s.unikey)
    })
    dispatch(createSiteActions.setSelectedStaff(selected))
    staffList.forEach(s => {
      if (!staff.find(ss => ss.unikey === s.unikey))
        staff.push(s)
    })
    dispatch(createSiteActions.setStaffList(staff))
  } else {
    staffList.forEach(s => {
      if (!staff.find(ss => ss.unikey === s.unikey))
        staff.push(s)
    })
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item md={12} lg={12} xl={12}>
          <Grid container direction='row' alignItems='center'>
            <Grid item md={12} lg={12} xl={12}>
              <Typography variant='h3' className='tabH3'>
                Select staff members to add to your new Canvas site
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 3 }} md={10} lg={10} xl={10}>
              <AddStaffTable rows={staff} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step3;
