import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  getUsersByGroupId,
  updateGroupRole,
  getTopAndFacultyGroupRoles,
} from '../utils/seams';
import Spinner from '../components/layout/Spinner';

const SeamsGroupUsers = () => {
  const [users, setUsers] = useState({});
  const [topGroupRoles, setTopGroupRoles] = useState([]);
  const [facultyGroupRoles, setFacultyGroupRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const { groupid } = useParams();

  useEffect(() => {
    (async () => {
      const [topGroupRoles, facultyGroupRoles] =
        await getTopAndFacultyGroupRoles();
      // console.log(topGroupRoles, facultyGroupRoles);
      setTopGroupRoles(topGroupRoles);
      setFacultyGroupRoles(facultyGroupRoles);

      const users = await getUsersByGroupId(groupid);
      // console.log(users);
      setUsers(users);
      setLoading(false);
    })();
  }, [groupid]);

  const updateGroupMembers = async (e) => {
    // Add user role to the group
    const [unikey, roleId] = e.target.name.split(':');
    // console.log(unikey, groupid, roleId, e.target.checked);
    if (e.target.checked) {
      await updateGroupRole('create', unikey, roleId, groupid);
    } else {
      await updateGroupRole('delete', unikey, roleId, groupid);
    }
  };

  if (loading) return <Spinner />;

  const dipslayRoles = (roles, unikey) => {
    return roles.map((role) => {
      const elementName = `${unikey}:${role.name}`;
      const checked = users[unikey].includes(role.name);
      return (
        <React.Fragment key={elementName}>
          <input
            type='checkbox'
            name={elementName}
            defaultChecked={checked}
            value='1'
            onChange={updateGroupMembers}
          />
          {role.label}
        </React.Fragment>
      );
    });
  };

  const unikeys = Object.keys(users).sort();
  // console.log(unikeys);
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <b>Pseron</b>
            </td>
            <td>
              <b>Role(s)</b>
            </td>
          </tr>
          {unikeys.map((unikey) => (
            <tr key={unikey}>
              <td>{unikey}</td>
              <td>
                {groupid === 'eam'
                  ? dipslayRoles(topGroupRoles, unikey)
                  : dipslayRoles(facultyGroupRoles, unikey)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        <Link to='/seams/membership'>Return</Link>
      </p>
    </>
  );
};

export default SeamsGroupUsers;
