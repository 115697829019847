import React, { Fragment } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../store/reducers/authReducer';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import logo from '../../assets/usydlogo.svg';

const Navbar = () => {
  const unikey = useSelector((state) => state.auth.unikey);
  const givenname = useSelector((state) => state.auth.givenname);
  const familyname = useSelector((state) => state.auth.familyname);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorAdminEl, setAnchorAdminEl] = React.useState(null);
  const openAdmin = Boolean(anchorAdminEl);
  const handleAdminClick = (event) => {
    setAnchorAdminEl(event.currentTarget);
  };
  const handleAdminClose = () => {
    setAnchorAdminEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    try {
      await Auth.signOut();
      dispatch(authActions.logout);
      window.location.replace('/');
    } catch (error) {
      console.error('Error signing user out: ', error);
    }
  };

  const authenticatedUser = () => {
    return (
      <Fragment>
        <Typography component='div' sx={{ flexGrow: 1 }}>
          <Button className='navbarTitle' component={Link} to='/'>
            Dashboard
          </Button>
          <Typography
            sx={{ cursor: 'pointer', marginLeft: '20px' }}
            className='navbarTitle'
            component='span'
            aria-controls='admin-menu'
            aria-haspopup='true'
            aria-expanded={openAdmin ? 'true' : undefined}
            onClick={handleAdminClick}
          >
            Manage SEAMS
          </Typography>
          <Menu
            id='admin-menu'
            anchorEl={anchorAdminEl}
            open={openAdmin}
            onClose={handleAdminClose}
          >
            <MenuItem
              onClick={handleAdminClose}
              component={Link}
              to='/seams/membership'
            >
              Membership
            </MenuItem>
            <MenuItem
              onClick={handleAdminClose}
              component={Link}
              to='/seams/settings'
            >
              Settings / Lookups
            </MenuItem>
          </Menu>
        </Typography>
        <Button
          color='secondary'
          variant='contained'
          className='navbarCreateButton'
          component={Link}
          to='/canvas/create-site'
        >
          Create Site
        </Button>
        <Typography className='navbarUser'>
          {givenname} {familyname}
        </Typography>
        <div>
          <IconButton
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <AccountCircleIcon />
            <ArrowDropDownIcon />
          </IconButton>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Fragment>
    );
  };

  const unauthenticatedUser = () => {
    return (
      <Fragment>
        <Typography component='div' sx={{ flexGrow: 1 }}>
          <Button className='navbarTitle' component={Link} to='/'>
            SEAMS
          </Button>
        </Typography>
        <Button
          variant='contained'
          className='navbarCreateButton'
          onClick={() =>
            Auth.federatedSignIn({
              customProvider: process.env.REACT_APP_IDP,
            })
          }
        >
          Login
        </Button>
      </Fragment>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar className='navbar'>
          <img src={logo} alt={'logo'} className='navbarLogo' />
          {!unikey && unauthenticatedUser()}
          {unikey && authenticatedUser()}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
