import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import AddCohortTable from '../tables/AddCohort';

export default function ManageAddCohort() {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item md={12} lg={12} xl={12}>
          <AddCohortTable />
        </Grid>
      </Grid>
    </Box>
  );
}
