import React, { useEffect } from 'react';

import { Auth } from 'aws-amplify';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignInButton,
} from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from '../store/actions/authActions';

const LoginPage = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      // console.log('authData', authData);
      if (authData) {
        dispatch(checkUser());
      }
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state && location.state.from) {
        navigate(location.state.from);
      } else {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated]);

  const loginWidget =
    process.env.REACT_APP_ENV === 'local' ? (
      <AmplifyAuthenticator>
        test
        <AmplifySignIn slot='sign-in'>
          <div slot='federated-buttons'>
            <AmplifySignInButton
              onClick={() =>
                Auth.federatedSignIn({
                  customProvider: process.env.REACT_APP_IDP,
                })
              }
            >
              Login using Unikey / Password
            </AmplifySignInButton>
          </div>
        </AmplifySignIn>
        test
      </AmplifyAuthenticator>
    ) : (
      <div
        className='federated-button-only'
        style={{
          height: '100vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '30% 70%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AmplifySignInButton
          onClick={() =>
            Auth.federatedSignIn({
              customProvider: process.env.REACT_APP_IDP,
            })
          }
          style={{ padding: '0 1rem' }}
        >
          Login using Unikey / Password
        </AmplifySignInButton>
      </div>
    );

  return loginWidget;
};

export default LoginPage;
