import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkUser } from './store/actions/authActions';
import AppRoutes from './routing/AppRoutes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007DFF',
    },
    secondary: {
      main: '#E64626',
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUser());
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
};

export default App;
