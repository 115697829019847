/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const auth = /* GraphQL */ `
  query Auth {
    auth
  }
`;
export const uos = /* GraphQL */ `
  query Uos($input: siteInput) {
    uos(input: $input)
  }
`;
export const session = /* GraphQL */ `
  query Session($input: sessionInput) {
    session(input: $input)
  }
`;
export const site = /* GraphQL */ `
  query Site($input: siteInput) {
    site(input: $input)
  }
`;
export const faculty = /* GraphQL */ `
  query Faculty($input: genericInput) {
    faculty(input: $input)
  }
`;
export const user = /* GraphQL */ `
  query User($input: genericInput) {
    user(input: $input)
  }
`;
export const role = /* GraphQL */ `
  query Role($input: roleInput) {
    role(input: $input)
  }
`;
export const enrollment = /* GraphQL */ `
  query Enrollment($input: siteInput) {
    enrollment(input: $input)
  }
`;
export const uosEnrollment = /* GraphQL */ `
  query UosEnrollment($input: siteInput) {
    uosEnrollment(input: $input)
  }
`;
export const emailTemplate = /* GraphQL */ `
  query EmailTemplate($input: genericInput) {
    emailTemplate(input: $input)
  }
`;
export const roleAdmin = /* GraphQL */ `
  query RoleAdmin {
    roleAdmin
  }
`;
export const canvasRestCourse = /* GraphQL */ `
  query CanvasRestCourse($input: canvasCourseInput) {
    canvasRestCourse(input: $input)
  }
`;
export const canvasRestEnrollment = /* GraphQL */ `
  query CanvasRestEnrollment($input: canvasEnrollmentInput) {
    canvasRestEnrollment(input: $input)
  }
`;
export const canvasRestUser = /* GraphQL */ `
  query CanvasRestUser($input: genericInput) {
    canvasRestUser(input: $input)
  }
`;
