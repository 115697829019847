import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import ManageSiteSettings from '../components/canvas/manage-site/tabs/ManageSiteSettings';
import ManageStaff from '../components/canvas/manage-site/tabs/ManageStaff';
import ManageAddCohort from '../components/canvas/manage-site/tabs/ManageAddCohort';
import ManageMarkingSection from '../components/canvas/manage-site/tabs/ManageMarkingSection';
import { getCourseIdBySeamsSectionId } from '../utils/canvas';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CANVAS_URL = process.env.REACT_APP_CANVAS_BASEURL;

const ManageSite = () => {
  const [value, setValue] = React.useState(0);

  const { sectionid } = useParams();
  const [site, setSite] = useState({});

  useEffect(() => {
    const getCourse = async () => {
      const res = await getCourseIdBySeamsSectionId(sectionid);
      setSite(res[0]);
    };

    getCourse();
  }, [sectionid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let canvasLink = (
    <a
      href={
        site && site.canvasid
          ? `${CANVAS_URL}/courses/${site.canvasid}`
          : `${CANVAS_URL}`
      }
      rel='noopener noreferrer'
      target='_blank'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        className='manageSiteUOSName'
      >
        <Typography sx={{ fontSize: '20px' }}>{sectionid}</Typography>
        <OpenInNewIcon sx={{ marginLeft: '0.5rem', fontSize: '12px' }} />
      </div>
    </a>
  );

  return (
    <Container className='mainContainer' maxWidth='lg'>
      <Box sx={{ width: '100%' }}>
        <Grid container>
          <Grid item md={12} lg={12} xl={12}>
            <Grid container direction='row' alignItems='center'>
              <Grid item md={12} lg={12} xl={12}>
                <Typography variant='h1' className='manageSiteH1'>
                  Manage Canvas Site
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                {canvasLink}
                <Typography variant='h2' className='manageSiteH2'>
                  Statistical thinking with data (Advanced)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12} lg={12} xl={12}>
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Canvas site settings' {...a11yProps(0)} />
                  <Tab label='Manage Staff' {...a11yProps(1)} />
                  <Tab label='Add student cohorts' {...a11yProps(2)} />
                  <Tab label='Create marking sections' {...a11yProps(3)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ManageSiteSettings />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManageStaff />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ManageAddCohort />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ManageMarkingSection />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ManageSite;
