// import { Auth, API } from 'aws-amplify';
import { createSiteActions } from '../reducers/createSiteReducer';
import { searchUos, getUoS, getSiteEnrollment, getFaculty } from '../../utils/seams';
import { createCourse, addUserWithLogMessage } from '../../utils/canvas';


export const searchUoS = (term) => async (dispatch) => {
  dispatch(createSiteActions.setUoSSearchTerm(term));
  dispatch(createSiteActions.setLoading());
  try {
    const results = await searchUos(term);
    dispatch(createSiteActions.setUoSSearchResults(results));
  } catch (error) {
    console.log('Error searching uos', term);
  }
};

export const getPrevSites = (uos_id) => async (dispatch) => {
  try {
    dispatch(createSiteActions.setLoading());
    const results = await getUoS(uos_id);
    dispatch(createSiteActions.setPrevUoSSites(results[0].history.filter(
        (o,i)=>results[0].history.indexOf(results[0].history.find(f=>f.id === o.id)) === i
    )));
  } catch (error) {
    console.log('Error fetching previous uos sites', uos_id);
  }
};
export const copyFromPreviousSite = (sis_id, staffList=[]) => async (dispatch) => {
  try {
    dispatch(createSiteActions.setCanvasCourse(sis_id));
    const results = await getSiteEnrollment(sis_id);
    if (results[0] && results[0].enrollments) {
      for (const role in results[0].enrollments) {
        results[0].enrollments[role].forEach((entry) => {
          if (entry.unikey) {
            staffList.push({
              ...entry,
              role: role,
            });
          }
        });
      }
    }
    dispatch(createSiteActions.setStaffList(staffList));
  } catch (error) {
    console.log('Error copying from previous site', sis_id);
  }
};

export const createUoSSite =
  (type, uos, users, copyFrom, startTime, endTime) => async (dispatch) => {
    dispatch(createSiteActions.setLoading());
    try {
      const createCourseRes = await createCourse(
        type,
        uos,
        users,
        copyFrom,
        startTime,
        endTime
      );
      const log = []
      let error = false
      if (createCourseRes.canvasSite) {
        if (createCourseRes.canvasSite.success) {
          log.push(`Canvas site created: ${createCourseRes.canvasSite.created.calendar.ics.replace(/feed.*$/, '')}courses/${createCourseRes.canvasSite.created.id}`)
          if (createCourseRes.canvasSite.migration) {
            if (createCourseRes.canvasSite.migration.settings) {
              log.push(`Content from ${createCourseRes.canvasSite.migration.settings.source_course_name} (${createCourseRes.canvasSite.migration.settings.source_course_id}) queued to be copied to your newly created site.`)
            } else if (createCourseRes.canvasSite.migration.message) {
              log.push(`Content migration failed: ${createCourseRes.canvasSite.migration.message}`)
            } else {
              console.log(createCourseRes.canvasSite.migration)
            }
          }
          if (createCourseRes.seamsSite && createCourseRes.seamsSite.rowsAffected) {
            log.push(`Site saved into SEAMS`)
          }
          if (createCourseRes.cohort && createCourseRes.cohort.rowsAffected) {
            log.push(`Cohort for ${uos.alpha+uos.digit}, ${uos.session.name ? uos.session.name : `${uos.session.period} ${uos.session.year}`} mapped to this course`)
          }
          if (createCourseRes.staff) {
            if (createCourseRes.staff.canvas) {
              log.push(`Staff queued to be granted permission to your Canvas course`)
            }
            if (createCourseRes.staff.seams.length) {
              log.push(`${createCourseRes.staff.seams.length} staff mapped to course in SEAMS`)
            }
          }
        } else {
          error = true
          log.push(`Canvas site failed to be created`)
        }
      } else {
        error = true
        log.push(`Something wrong happened here. No Canvas site created.`)
      }
      dispatch(createSiteActions.setCreateSiteLog(log))
      if (!error)
        dispatch(createSiteActions.reset())
    } catch (error) {
      console.error('Error creating uos site', error);
      dispatch(createSiteActions.setCreateSiteLog([`Error creating uos site ${error.errors[0].message}`]))
    }
  };
