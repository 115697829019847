import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';
import { useSelector, useDispatch } from 'react-redux';
import DeliveryModeModal from '../modals/DeliveryMode';
import { createSiteActions } from '../../../../store/reducers/createSiteReducer';
import { getSessionByYearAndPeriod} from "../../../../utils/seams";


export default function SearchUoS(props) {
  const dispatch = useDispatch();
  const {selectedUoS, uoSSearchResults} = useSelector((state) => state.createSite);
  const {auth} = useSelector(s=>s)

  const transformedRows = props.rows.map((row) => {
    const o = {
      id: row.id,
      unitOfStudyName: row.name,
      year: row.session.year,
      session: row.session.name,
      sessionObj: row.session,
      deliveryMode: row.deliverymode,
      students: row.totalenrolled,
      exists: !!row.seamsrequestid,
      className: !!row.canvas ? "exists" : ""
    }
    return o
  }).sort(
      (a, b) => {
        if (a.exists === b.exists) {
          if (b.year === a.year)
            return a.unitOfStudyName > b.unitOfStudyName
          return b.year - a.year
        }
        return a.exists*1 - b.exists*1
      }
  );

  const columns = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params) => (
          params.row.exists ? null :
        <Radio checked={!params.row.exists && selectedUoS === params.id} value={params.id} disabled={params.row.exists}/>
      ),
    },
    {
      field: 'unitOfStudyName',
      headerName: 'Unit of Study / Course Name',
      width: 350,
      sortable: false,
    },
    {
      field: 'year',
      headerName: 'Year',
      width: 170,
      sortable: false,
    },
    {
      field: 'session',
      headerName: 'Session',
      width: 170,
      sortable: false,
    },
    {
      field: 'deliveryMode',
      headerName: <DeliveryModeModal />,
      width: 170,
      sortable: false,
    },
    {
      field: 'students',
      headerName: 'Students',
      width: 170,
      sortable: false,
    },
  ];

  const uosSelectHandler = async (newSelectionModel) => {
    if (newSelectionModel[0]) {
      const row = transformedRows.find(r=>r.id === newSelectionModel[0])
      if (!row.exists) {
        dispatch(createSiteActions.setSelectedUoS(newSelectionModel[0]));
        const session = await getSessionByYearAndPeriod(row.sessionObj)
        dispatch(createSiteActions.setStartTime(session[0].access.start))
        dispatch(createSiteActions.setEndTime(session[0].access.end))
      }
    }
  };
  return (
    <div className='App'>
      {transformedRows.length ?
        <DataGrid
        rows={transformedRows}
        columns={columns}
        autoHeight
        selectionModel={selectedUoS ? [selectedUoS] : []}
        onSelectionModelChange={uosSelectHandler}
      /> :
      <p>No Units of Study returned based on the entered search terms.</p>}
    </div>
  );
}
