import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {
  const [role, setRole] = React.useState(props.role);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant='standard' style={{ width: '400px' }}>
        <InputLabel id='demo-simple-select-label'>Role</InputLabel>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={role}
          label='Age'
          onChange={handleChange}
        >
          <MenuItem value={'extra_coordinator'}>
            Extra Coordinator / Primary Learning Designer
          </MenuItem>
          <MenuItem value={'instructor'}>Instructor</MenuItem>
          <MenuItem value={'designer'}>Learning Designer</MenuItem>
          <MenuItem value={'teaching_assistant'}>Teaching Assistant</MenuItem>
          <MenuItem value={'auditor'}>Observer</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
