import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

import DeliveryModeGraphic from '../../../../assets/deliverymode.svg';

export default function OutlineModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      Delivery Mode
      <IconButton aria-label='help' onClick={handleClickOpen}>
        <HelpIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle className='dialogText'>
          Types of delivery modes explained
          <IconButton onClick={handleClose} className='dialogExit'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className='dialogTextTitle'>
              What are delivery modes?
            </Typography>
            <Typography className='dialogSubText'>
              Delivery modes represent how a course is taught. The university
              has 11 different types of course delivery modes.
            </Typography>
            <Typography className='dialogTextTitle'>
              Why are they important?
            </Typography>
            <Typography className='dialogSubText'>
              It's important to select the correct delivery mode for your unit
              of study as that will impact the students who are enroled in your
              Canvas site.
            </Typography>
            <Typography className='dialogTextTitle'>
              Types of delivery modes
            </Typography>
            <img
              src={DeliveryModeGraphic}
              alt={'DeliveryModeGraphic'}
              className='DeliveryModeGraphic'
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
