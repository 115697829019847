import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChangeStaff = (event, newValue) => {
    setValue(newValue);
  };

  const [role, setRole] = React.useState('');

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  return (
    <div>
      <Fab color='primary' variant='extended' onClick={handleClickOpen}>
        <AddIcon sx={{ mr: 1 }} />
        Staff
      </Fab>
      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='add-staff-dialog'
        className='addStaffDialog'
      >
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            height: 400,
          }}
        >
          <Tabs
            orientation='vertical'
            value={value}
            onChange={handleChangeStaff}
            aria-label='Vertical tabs example'
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label='Add staff individually' {...a11yProps(0)} />
            <Tab label='Upload staff list' {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DialogTitle>Add staff to my Canvas site</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <Typography className='addStaffTitle'>
                    Search for staff members via Unikey or name
                  </Typography>
                  <TextField
                    id='enter-unikey-staff'
                    label='Enter Unikey or staff name'
                    variant='standard'
                  />
                </div>
                <div style={{ marginTop: '2rem' }}>
                  <Typography className='addStaffTitle'>
                    Assign role in Canvas
                  </Typography>
                  <FormControl variant='standard' fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Select role
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={role}
                      label='Select role'
                      onChange={handleChangeRole}
                    >
                      <MenuItem value={10}>Extra Coordinator</MenuItem>
                      <MenuItem value={20}>Primary Learning Designer</MenuItem>
                      <MenuItem value={30}>Instructor</MenuItem>
                      <MenuItem value={40}>Teaching Assistant</MenuItem>
                      <MenuItem value={50}>Learning Designer</MenuItem>
                      <MenuItem value={60}>Auditor</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Button
                  variant='contained'
                  onClick={handleClose}
                  sx={{ mt: 5 }}
                >
                  Add Staff
                </Button>
              </DialogContentText>
            </DialogContent>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DialogTitle>Upload Staff List</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Button variant='contained' endIcon={<UploadIcon />}>
                  Upload
                  <input hidden accept='image/*' multiple type='file' />
                </Button>
              </DialogContentText>
            </DialogContent>
          </TabPanel>
        </Box>
      </Dialog>
    </div>
  );
}
