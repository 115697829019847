import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { createSiteActions } from '../../store/reducers/createSiteReducer';

export default function DatePickerEnd() {
  const dispatch = useDispatch();
  const endTime = useSelector((state) => state.createSite.endTime);

  return (
    <div>
      <DatePicker
        selected={new Date(endTime)}
        onChange={(date) =>
          dispatch(createSiteActions.setEndTime(date.toISOString()))
        }
        className='datePicker'
        dateFormat='eeee MMMM d, yyyy'
      />
    </div>
  );
}
