import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  unikey: null,
  givenname: null,
  familyname: null,
  canvas: null,
  permissions: [],
  coordinator: [],
  siteMembership: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.unikey = action.payload.unikey;
      state.givenname = action.payload.givenname;
      state.familyname = action.payload.familyname;
      state.permissions = action.payload.permission;
      state.coordinator = action.payload.coordinator;
      state.siteMembership = action.payload.siteMembership;
      state.canvas = action.payload.canvas;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.unikey = null;
      state.givenname = null;
      state.familyname = null;
      state.permissions = [];
      state.coordinator = [];
      state.siteMembership = [];
      state.canvas = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
