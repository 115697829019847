import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  getUserByUnikey,
  fetchFaculties,
  updateGroupRole,
  getTopAndFacultyGroupRoles,
} from '../utils/seams';
import Spinner from '../components/layout/Spinner';

const SeamsUserGroups = () => {
  const [user, setUser] = useState([]);
  const [faculties, setFaculties] = useState(null);
  const [topGroupRoles, setTopGroupRoles] = useState([]);
  const [facultyGroupRoles, setFacultyGroupRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userid } = useParams();

  useEffect(() => {
    (async () => {
      const userInfo = await getUserByUnikey(userid);
      setUser(userInfo);

      const [topGroupRoles, facultyGroupRoles] =
        await getTopAndFacultyGroupRoles();
      // console.log(topGroupRoles, facultyGroupRoles);
      setTopGroupRoles(topGroupRoles);
      setFacultyGroupRoles(facultyGroupRoles);

      const faculties = await fetchFaculties();
      faculties.sort((a, b) => a.name.localeCompare(b.name));

      setFaculties(faculties);
      setLoading(false);
    })();
  }, [userid]);

  const updateMembership = async (e) => {
    const [faculty, role] = e.target.name.split(':');
    if (e.target.checked) {
      await updateGroupRole('create', userid, role, faculty);
    } else {
      await updateGroupRole('delete', userid, role, faculty);
    }
  };

  if (loading) return <Spinner />;

  const userPermissions = user.map((p) => `${p.faculty}:${p.role}`);

  const dipslayRoles = (roles, prefix = 'eam') => {
    return roles.map((role) => {
      const elementName = `${prefix}:${role.name}`;
      const checked = userPermissions.includes(elementName);
      return (
        <React.Fragment key={elementName}>
          <input
            type='checkbox'
            name={elementName}
            defaultChecked={checked}
            value='1'
            onChange={updateMembership}
          />
          {role.label}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <table>
        <tbody>
          <tr>
            <th colSpan={2}>Top Group</th>
          </tr>
          <tr>
            <td>
              <b>Group</b>
            </td>
            <td>
              <b>Role(s)</b>
            </td>
          </tr>
          <tr>
            <td>EAM</td>
            <td>{dipslayRoles(topGroupRoles)}</td>
          </tr>

          <tr>
            <th colSpan={2}>Faculty</th>
          </tr>
          <tr>
            <td>
              <b>Group</b>
            </td>
            <td>
              <b>Role(s)</b>
            </td>
          </tr>

          {faculties.map((faculty) => (
            <tr key={faculty.alias}>
              <td>{faculty.name}</td>
              <td>{dipslayRoles(facultyGroupRoles, faculty.alias)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <Link to='/seams/membership'>Return</Link>
      </p>
    </>
  );
};

export default SeamsUserGroups;
