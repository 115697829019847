import React, { useState, useEffect } from 'react';
import { fetchRoles } from '../../utils/seams';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';

const SeamsMembership = () => {
  const [membership, setMembership] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const roles = await fetchRoles();
      // console.log(roles);
      const members = [];
      roles.forEach((role) => {
        const newArray = role.members.map((member) => ({
          ...member,
          groupId: role.faculty && role.faculty.alias,
          roleId: role.id,
        }));
        members.push(...newArray);
      });
      // console.log(members);
      members.sort(
        (a, b) => +!a.email - +!b.email || a.email.localeCompare(b.email)
      );

      setMembership(members);
      setLoading(false);
    })();
  }, []);

  if (loading) return <Spinner />;

  return (
    <div className='page'>
      <h5>Membership</h5>
      <table className='table table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>Email</th>
            <th scope='col'>Name</th>
            <th scope='col'>Group ID</th>
            <th scope='col'>Role ID</th>
            <th scope='col'>User ID</th>
          </tr>
        </thead>
        <tbody>
          {membership.map((member, index) => (
            <tr key={index}>
              <td>{member.email}</td>
              <td>{member.name}</td>
              <td>
                {member.groupId && (
                  <Link to={`/seams/membership/group/${member.groupId}`}>
                    {member.groupId}
                  </Link>
                )}
                {!member.groupId && member.roleId === 'eamadm' && (
                  <Link to='/seams/membership/group/eam'>eam</Link>
                )}
              </td>
              <td>{member.roleId}</td>
              <td>
                <Link to={`/seams/membership/user/${member.unikey}`}>
                  {member.unikey}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SeamsMembership;
