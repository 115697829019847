import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { faculty } from '../../graphql/queries';

const Faculties = () => {
  const [faculties, setFaculties] = useState([]);
  useEffect(() => {
    const fetchFaculties = async () => {
      const res = await API.graphql({
        query: faculty,
        variables: { input: { id: null, search: null } },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      setFaculties(JSON.parse(res.data.faculty));
    };

    fetchFaculties();
  }, []);

  return (
    <div className='page'>
      <h5>All Faculties</h5>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th scope='col'>Faculty Alias</th>
            <th scope='col'>Faculty</th>
          </tr>
        </thead>
        <tbody>
          {faculties.map((faculty) => (
            <tr key={faculty.alias}>
              <td>{faculty.alias}</td>
              <td>{faculty.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Faculties;
