import React from 'react';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const Home = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (isAuthenticated) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <Container className='mainContainer' maxWidth='xl'>
      <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth='sm'>
          <Typography
            component='h1'
            variant='h2'
            align='center'
            color='text.primary'
            gutterBottom
          >
            Welcome to SEAMS
          </Typography>
          <Typography
            variant='h5'
            align='center'
            color='text.secondary'
            paragraph
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad numquam
            cum deleniti quaerat omnis veritatis qui voluptas quis deserunt
            eius! Hic sint maiores itaque soluta porro eaque, quod laborum
            sapiente.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction='row'
            spacing={2}
            justifyContent='center'
          >
            <Button
              variant='contained'
              onClick={() =>
                Auth.federatedSignIn({
                  customProvider: process.env.REACT_APP_IDP,
                })
              }
            >
              Login
            </Button>
          </Stack>
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
