import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import SearchUOSTable from '../tables/SearchUoS';

import Spinner from '../../../layout/Spinner';
import { searchUoS } from '../../../../store/actions/createSiteActions';

const Step1 = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.createSite.loading);
  const uosTerm = useSelector((state) => state.createSite.uoSSearchTerm);
  const uosResults = useSelector((state) => state.createSite.uoSSearchResults);
  const [term, setTerm] = useState(uosTerm);


  const searchHandler = (e) => {
    e.preventDefault();
    dispatch(searchUoS(term));
  };
console.log(uosTerm)
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} sx={{ mb: 2 }}>
          <Typography variant='span' className='tabH3'>
            Find a unit of study by name or code to add to your new Canvas site
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={3}>
          <TextField
            id='outlined-basic'
            label='Search Unit of Study'
            variant='outlined'
            size='small'
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            fullWidth
          />
          <Typography className='helperText'>
            Find a unit of study code{' '}
            <a
              href='https://www.sydney.edu.au/units'
              rel='noopener noreferrer'
              target='_blank'
            >
              here
            </a>
          </Typography>
        </Grid>
        <Grid item lg={9}>
          <Button
            onClick={searchHandler}
            variant='outlined'
            size='medium'
            sx={{ ml: 2 }}
            className='searchButton'
          >
            Search
          </Button>
        </Grid>
      </Grid>
      {loading && <Spinner />}
      {!loading && uosTerm && (
        <Grid container>
          <Grid item lg={12} sx={{ mt: 3 }}>
            {uosResults.length ? <SearchUOSTable rows={uosResults} /> : <p>No Units of Study returned based on entered search terms.</p>}
          </Grid>
        </Grid>
      )
      }
    </Box>
  );
};

export default Step1;
