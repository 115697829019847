import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import EditIcon from '@mui/icons-material/Edit';
import DotIcon from '@mui/icons-material/FiberManualRecord';

export default function ManageSiteSettings() {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={8}>
        <Grid item md={6} lg={6} xl={6}>
          <Typography variant='h2' className='manageSiteSettingsH1'>
            Canvas site status
          </Typography>
          <Chip
            color='primary'
            label='Canvas site content has been approved and site is active'
            sx={{ mt: 1, width: '100%' }}
          />
          <div className='confirmManageDiv'>
            <Grid container direction='row' alignItems='center'>
              <Grid item md={11} lg={11} xl={11}>
                <Typography variant='h4' className='confirmDate'>
                  Student Access Date
                </Typography>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
                <IconButton aria-label='first page'>
                  <EditIcon className='confirmEditIcon' />
                </IconButton>
              </Grid>
            </Grid>
            <Divider className='informationDivider' />
            <Grid container direction='row' alignItems='center'>
              <Grid item md={12} lg={12} xl={12} sx={{ mb: 3 }}>
                <Typography variant='h4' className='confirmTitle'>
                  Start date - site goes live if activated
                </Typography>
                <Typography variant='h5' className='confirmInformation'>
                  Mon, Feb 01 2022
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Typography variant='h4' className='confirmTitle'>
                  End date - site becomes view-only for students
                </Typography>
                <Typography variant='h5' className='confirmInformation'>
                  Fri, Jun 24 2022
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Typography variant='h2' className='manageSiteSettingsH1'>
            Unit Site Status
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              margin: '0.5rem 0',
            }}
          >
            <DotIcon className='manageSiteSettingsDotPublish' />
            <span>MATH100OL - Published</span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              margin: '0.5rem 0',
            }}
          >
            <DotIcon className='manageSiteSettingsDotUnpublish' />
            <span>MATH100OL - Not Published</span>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xl={6}>
          <div style={{ marginBottom: '1rem' }}>
            <Grid container direction='row' alignItems='center'>
              <Typography className='manageSiteSettingsEditTitle'>
                Canvas site title
              </Typography>
              <Grid item md={11} lg={11} xl={11}>
                <Typography className='manageSiteSettingsEdit'>
                  Statistical thinking with data (Advanced)
                </Typography>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
                <IconButton aria-label='first page'>
                  <EditIcon className='confirmEditIcon' />
                </IconButton>
              </Grid>
            </Grid>
            <Divider className='informationDivider' />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Grid container direction='row' alignItems='center'>
              <Typography className='manageSiteSettingsEditTitle'>
                Primary unit coordinator
              </Typography>
              <Grid item md={11} lg={11} xl={11}>
                <Typography className='manageSiteSettingsEdit'>
                  Frank Smith | Engineering
                </Typography>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
                <IconButton aria-label='first page'>
                  <EditIcon className='confirmEditIcon' />
                </IconButton>
              </Grid>
            </Grid>
            <Divider className='informationDivider' />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <Grid container direction='row' alignItems='center'>
              <Typography className='manageSiteSettingsEditTitle'>
                Extra unit coordinator (optional)
              </Typography>
              <Grid item md={11} lg={11} xl={11}>
                <Typography className='manageSiteSettingsEdit'>
                  Add Staff
                </Typography>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
                <IconButton aria-label='first page'>
                  <EditIcon className='confirmEditIcon' />
                </IconButton>
              </Grid>
            </Grid>
            <Divider className='informationDivider' />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
