import { createSlice } from '@reduxjs/toolkit';

const initialCreateSiteState = {
  loading: false,
  siteType: 'units-of-study-site',
  selectedUoS: null,
  selectedUoSObject: null,
  uoSSearchTerm: '',
  uoSSearchResults: [],
  copyContent: '',
  sisCourseId: '',
  prevUoSSites: [],
  prevUoSSiteObject: null,
  staffList: [],
  selectedStaff: [],
  startTime: new Date().toISOString(),
  endTime: new Date().toISOString(),
  createSiteLog: [],
};

const createSiteSlice = createSlice({
  name: 'createSite',
  initialState: initialCreateSiteState,
  reducers: {
    setLoading(state, action) {
      state.loading = true;
    },
    setSiteType(state, action) {
      state.uoSSearchTerm = ''
      state.createSiteLog = []
      state.siteType = action.payload;
    },
    setSelectedUoS(state, action) {
      state.selectedUoS = action.payload;
      state.selectedUoSObject = state.uoSSearchResults.find(
        (uos) => uos.id === action.payload
      );
      state.copyContent = '';
      state.prevUoSSites = [];
      state.sisCourseId = '';
      state.prevUoSSiteObject = null;
      state.staffList = [];
      state.selectedStaff = [];
      state.createSiteLog = [];
    },
    setUoSSearchTerm(state, action) {
      state.uoSSearchTerm = action.payload;
      state.uoSSearchResults = [];
      state.selectedUoS = null;
      state.selectedUoSObject = null;
      state.copyContent = '';
      state.prevUoSSites = [];
      state.sisCourseId = '';
      state.prevUoSSiteObject = null;
      state.staffList = [];
      state.selectedStaff = [];
    },
    setUoSSearchResults(state, action) {
      state.loading = false;
      state.uoSSearchResults = action.payload;
    },
    setCopyContent(state, action) {
      state.copyContent = action.payload;
    },
    setCanvasCourse(state, action) {
      state.sisCourseId = action.payload;
      state.prevUoSSiteObject = state.prevUoSSites.find(
        (site) => site.canvas.sisId === action.payload
      );
      state.staffList = [];
      state.selectedStaff = [];
    },
    setStaffList(state, action) {
      state.staffList = action.payload;
    },
    setSelectedStaff(state, action) {
      state.selectedStaff = action.payload;
    },
    setPrevUoSSites(state, action) {
      state.loading = false;
      state.prevUoSSites = action.payload;
    },
    setStartTime(state, action) {
      state.startTime = action.payload;
    },
    setEndTime(state, action) {
      state.endTime = action.payload;
    },
    setCreateSiteLog(state, action) {
      state.loading = false
      state.createSiteLog = action.payload
    },
    reset(state, action) {
      state.loading = false;
      state.siteType = 'units-of-study-site';
      state.selectedUoS = null;
      state.selectedUoSObject = null;
      state.uoSSearchTerm = '';
      state.uoSSearchResults = [];
      state.copyContent = '';
      state.sisCourseId = '';
      state.prevUoSSiteObject = null;
      state.prevUoSSites = [];
      state.staffList = [];
      state.selectedStaff = [];
      state.startTime = new Date().toISOString();
      state.endTime = new Date().toISOString();
    },
  },
});

export const createSiteActions = createSiteSlice.actions;

export default createSiteSlice.reducer;
