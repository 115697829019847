import React from 'react';
import { API } from 'aws-amplify';
import {
  canvasRestUser,
  canvasRestCourse,
  canvasRestEnrollment,
  uosEnrollment,
  site,
} from '../graphql/queries';
import {
  canvasRestAddEnrollment,
  canvasRestCreateUser,
  canvasRestCreateCourse,
  seamsUpdateEnrollment,
  seamsCreateSite,
  seamsEditCohort,
  seamsActivateSite,
} from '../graphql/mutations';

export const getEnrollments = async (course, type = []) => {
  const res = await API.graphql({
    query: canvasRestEnrollment,
    variables: {
      input: {
        course: course,
        type: type
      }
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  })
  return JSON.parse(res.data.canvasRestEnrollment)
}

export const editEnrollment = async ({ newUsers, course, updateUser }) => {
  console.log(newUsers, course, "editEnrollment")
  const out = {
    canvas: null,
    seams: []
  }
  let tmp = await API.graphql({
        query: canvasRestAddEnrollment,
        variables: {
          courseId: course,
          post: {
            enrollments: newUsers ? newUsers.map(u=>({user: u.user, type: u.type})) : null,
            updateEnrollment: updateUser ? {user: updateUser.user, type: updateUser.type} : null
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
  })
  out.canvas = JSON.parse(tmp.data.canvasRestAddEnrollment)
  const users = (updateUser ? updateUser : []).concat(newUsers)

  for (let x = 0; x < users.length; x++) {
    tmp = await API.graphql({
      query: seamsUpdateEnrollment,
      variables: {
        post: {
          course: course,
          person: users[x].user,
          role: users[x].type,
          activate: true,
          isPrimary: users[x].isPrimary,
          importId: out.canvas.id
        }
      }
    })
    out.seams.push( JSON.parse(tmp.data.seamsUpdateEnrollment) )
  }
  return out
};

export const addUserWithLogMessage = async (users, course, log) => {
  try {
    const enrol = await editEnrollment({
      newUsers: users.map((u) => {
        return {
          user: { id: u.value.canvasid, sisId: u.value.canvas_sis },
          type: mapSeamsToCanvasRole(u.type),
          enrollment_state: 'active',
        };
      }),
      course: course,
    });
    if (enrol && enrol.id) {
      users.forEach((u) => {
        log.push(
          <p>
            User {u.value.givenname} {u.value.familyname} queued to be added to
            course {enrol.data.course_id} as {u.type}
          </p>
        );
      });
      log.push(<p>Queued request has an Import ID {enrol.id}</p>);
    }
  } catch (error) {
    log.push(
      <p>
        Users {users.map((u, i) => `${i ? ', ' : ''}${u.value.displayName}`)}{' '}
        not added to course {course.id}
      </p>
    );
  }
};

export const createCourse = async (
  type,
  uos,
  users,
  copyFrom,
  startTime,
  endTime
) => {
  const course = {
    conditions: {
      type: type,
      department: uos.department.alias,
      faculty: uos.department.faculty,
    },
    copySite: copyFrom ? copyFrom.canvas.id : null, //Commenting out since Canvas is out of sync with SEAMS
    name: uos
      ? `${uos.session.name.replace(
          /^(.*) +(\d{4})$/,
          '$2, $1'
        )} - ${uos.name.replace(/^(\\w{4}\\d{4}).*$/, '$1')}`
      : null, //String!
    course_code: uos
      ? `${uos.session.year}_${uos.session.period}_${uos.alpha}${uos.digit}_${uos.deliverymode}`
      : null, //String!
    sis_course_id: uos
      ? `${uos.session.year}_${uos.session.period}_${uos.alpha}${uos.digit}_${uos.deliverymode}`
      : null, //String!
    term_id: uos ? `sis_term_id:${uos.session.year}_${uos.session.period}` : null, //String!
    start_at: startTime, //String!
    end_at: endTime, //String!
  },
      out = {}
  const missing = []; let coordinator = users.find(u=>u.isCoordinator);
  if (!type) missing.push(`Site type to be created not defined!`);
  if (!uos) missing.push(`No UoS defined!`);
  if (
    uos &&
    uos.coordinator &&
    uos.coordinator.unikey &&
    !coordinator
  ) {
    users.coordinator = uos.coordinator.unikey;
    coordinator = uos.coordinator
  }
  if (!coordinator && (!uos.coordinator || !uos.coordinator.unikey))
    missing.push(`No coordinator identified!`);
  if (!startTime || !endTime) missing.push(`No period for the course defined!`);
  if (missing.length) throw new Error(missing.join('\n'));
  let res = await API.graphql({
    query: canvasRestCreateCourse,
    variables: {
      post: course
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  if (!res) {
    throw new Error(`Failed to create Canvas site!`);
  }
  res = JSON.parse(res.data.canvasRestCreateCourse);
  out.canvasSite = res
  if (res && res.created && res.created.id) {
    let seams = await API.graphql({
      query: seamsCreateSite,
      variables: {
        post: {
          type: type,
          coordinatorUnikey:
            coordinator ? coordinator.unikey : null,
          uos: {
            code: `${uos.alpha}${uos.digit}`,
            session: uos.session,
            name: `${uos.name}`,
            deliveryMode: `${uos.deliverymode}`,
          },
          start_at: startTime,
          end_at: endTime,
          status: 'okay',
          cohortManage: true,
          canvas: {
            id: res.created.id,
            sisId: course.sis_course_id,
          },
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    seams = JSON.parse(seams.data.seamsCreateSite)
    out.seamsSite = seams
    let cohort = await API.graphql({
      query: seamsEditCohort,
      variables: {
        post: {
          action: 'create',
          sis_course_id: course.sis_course_id,
          uos: {
            code: uos.alpha + uos.digit,
            session: uos.session,
            name: uos.name,
            deliveryMode: uos.deliverymode,
            campus: uos.campus
          },
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    cohort = JSON.parse(cohort.data.seamsEditCohort)
    out.cohort = cohort
    const enrol = await editEnrollment({
      course: { id: res.created.id, sisId: course.sis_course_id },
      newUsers: users.map(u=>({
          user: u.canvas,
          type: mapSeamsToCanvasRole(u.role),
          isPrimary: u.isPrimary
        })
      )
    })
    out.staff = enrol
    return out
  } else {
    console.error(res)
  }
};

export const getCourseIdBySeamsSectionId = async (sectionId) => {
  const res = await API.graphql({
    query: site,
    variables: {
      input: { id: sectionId },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  return JSON.parse(res.data.site);
};

export const activateCourse = async (courseId, activate = true) => {
  let res;
  const seams = await API.graphql({
    mutation: seamsActivateSite,
    variables: {
      post: {
        course: courseId,
        activate: activate,
      },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  if (activate) {
    let enrol = await API.graphql({
      query: uosEnrollment,
      variables: {
        input: {
          id: courseId,
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    enrol = JSON.parse(enrol.data.uosEnrollment);
    res = await API.graphql({
      query: canvasRestAddEnrollment,
      variables: {
        courseId: courseId,
        enrollments: enrol[0].enrollments.map(s => ({ user: s.canvas.sisId, type: 'student' }) ),
        additional: `batch_mode=true&batch_mode_term_id=${enrol[0].session.year}_${enrol[0].session.period}`,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  }
  return JSON.parse(res ? res : seams);
};

const mapSeamsToCanvasRole = (seamsRole, enrollmentApi = false) => {
  if (seamsRole.match(/( Coordinator|^Instructor)$/i))
    return enrollmentApi ? 'TeacherEnrollment' : 'teacher';
  else if (seamsRole === 'Teaching assistant' || seamsRole === 'teaching_assistant')
    return enrollmentApi ? 'TaEnrollment' : 'ta';
  else if (seamsRole === 'Designer' || seamsRole === "designer")
    return enrollmentApi ? 'DesignerEnrollment' : 'designer';
  else if (seamsRole === 'Auditor' || seamsRole === "auditor")
    return enrollmentApi ? 'ObserverEnrollment' : 'observer';
  else {
    console.log("No mapping for " + seamsRole)
    return enrollmentApi ? 'StudentEnrollment' : 'student';
  }
};