import React, { lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import CircularProgress from '@mui/material/CircularProgress';

import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import BuildIcon from '@mui/icons-material/Build';

import './Dashboard.css';

const DashboardPublishTab = lazy(() =>
  import('../components/dashboard/tabs/Publish.js')
);

const DashboardManageTab = lazy(() =>
  import('../components/dashboard/tabs/Manage.js')
);

const DashboardViewOnlyTab = lazy(() =>
  import('../components/dashboard/tabs/ViewOnly.js')
);

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-size: 0.875rem;
  margin: 0 1rem;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #0057b2;
    transition: background-color 0.4s ease;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #0057b2;
  }
  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  border-radius: 8px;
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const Dashboard = () => {
  const [summary, setSummary] = useState({outlines: 0, pending: 0, active: 0, previous: 0})
  const coordinator = useSelector((state) => state.auth.coordinator);
  const siteMembership = useSelector((state) => {
    return state.auth.siteMembership ? state.auth.siteMembership : [];
  });
  useEffect(() => {
    const currentSession = {
      year: new Date().getFullYear(),
      period: `S${new Date().getMonth() < 6 ? 1 : 2}C`,
    };
    if (coordinator && "length" in coordinator) {
      coordinator.forEach((c) => {
        if (c.outline.published !== 'published') summary.outlines++;
      });
    }
    if (siteMembership && "length" in siteMembership && !summary.active && !summary.previous) {
      siteMembership.forEach((s) => {
        if (s.isPrimary) {
          summary.active++;
        } else {
          summary.previous++
        }
      });
    }
    setSummary(summary)
  }, []);

  const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: 'theme.palette.background.paper',
  }));

  return (
    <Container className='mainContainer' maxWidth='lg'>
      <Box>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{ mb: 4 }}
        >
          <Grid item xl={12}>
            <Typography variant='h1' className='dashboardH1'>
              SEAMS Dashboard
            </Typography>
            <Typography className='dashboardH1Sub'>
              Unit of Study Coordinator
            </Typography>
          </Grid>
        </Grid>
        <TabsUnstyled defaultValue={false}>
          <TabsList>
            <Tab>
              <Card sx={{ minWidth: '250px', m: '2px' }}>
                <CardContent className='cardContent'>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    className='cardTitle'
                  >
                    <Grid item>
                      <EditIcon className='dashboardIcon' />
                    </Grid>
                    <Grid item>Publish outlines</Grid>
                  </Grid>
                  <Typography
                    variant='h3'
                    component='div'
                    className='cardValue'
                  >
                    {summary.outlines}
                  </Typography>
                  <Div>{'Unit of Study Outlines'}</Div>
                </CardContent>
              </Card>
            </Tab>
            <Tab>
              <Card sx={{ minWidth: '250px', m: '2px' }}>
                <CardContent className='cardContent'>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    className='cardTitle'
                  >
                    <Grid item>
                      <BuildIcon className='dashboardIcon' />
                    </Grid>
                    <Grid item>Manage</Grid>
                  </Grid>
                  <Typography
                    variant='h3'
                    component='div'
                    className='cardValue'
                  >
                    {summary.active}
                  </Typography>
                  <Div>{'Current Canvas Sites'}</Div>
                </CardContent>
              </Card>
            </Tab>
            <Tab>
              <Card sx={{ minWidth: '250px', m: '2px' }}>
                <CardContent className='cardContent'>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    className='cardTitle'
                  >
                    <Grid item>
                      <HistoryIcon className='dashboardIcon' />
                    </Grid>
                    <Grid item>View-only</Grid>
                  </Grid>
                  <Typography
                    variant='h3'
                    component='div'
                    className='cardValue'
                  >
                    {summary.previous}
                  </Typography>
                  <Div>{'Past Canvas Sites'}</Div>
                </CardContent>
              </Card>
            </Tab>
          </TabsList>
          <React.Suspense fallback={<CircularProgress />}>
            <TabPanel value={0}>
              <DashboardPublishTab />
            </TabPanel>
            <TabPanel value={1}>
              <DashboardManageTab />
            </TabPanel>
            <TabPanel value={2}>
              <DashboardViewOnlyTab />
            </TabPanel>
          </React.Suspense>
        </TabsUnstyled>
      </Box>
    </Container>
  );
};

export default Dashboard;
