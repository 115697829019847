import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import AddIcon from '@mui/icons-material/Add';

import AddCohortSearchUOSTable from '../tables/AddCohortSearchUoS';

export default function AlertDialog() {
  const [show, setShow] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab color='primary' variant='extended' onClick={handleClickOpen}>
        <AddIcon sx={{ mr: 1 }} />
        Cohort
      </Fab>
      <Dialog
        maxWidth='lg'
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Add a cohort to your Canvas site
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Box>
              <Grid container>
                <Grid item lg={12} sx={{ mb: 2 }}>
                  <Typography variant='h3' className='tabH3'>
                    Find a unit of study by name or code to add to your new
                    Canvas site
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={3}>
                  <TextField
                    id='outlined-basic'
                    label='Search Unit of Study'
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                  <Typography className='helperText'>
                    Find a unit of study code{' '}
                    <a
                      href='https://www.sydney.edu.au/units'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      here
                    </a>
                  </Typography>
                </Grid>
                <Grid item lg={9}>
                  <Button
                    onClick={() => setShow((prev) => !prev)}
                    variant='outlined'
                    size='medium'
                    sx={{ ml: 2 }}
                    className='searchButton'
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
              {show && (
                <Grid container>
                  <Grid item lg={12} sx={{ mt: 3 }}>
                    <AddCohortSearchUOSTable />
                    <Button color='primary' variant='contained' sx={{ mt: 2 }}>
                      Add unit of study
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
