export const copyContentOptions = [
  {
    label: 'Copy from previous Canvas Sites',
    value: 'copy-prev-site',
  },
  { label: 'A faculty/school template', value: 'faculty-template' },
  {
    label: 'Nothing - give me a completely blank site',
    value: 'blank-site',
  },
];
