import React, { useState, useEffect } from 'react';
import { fetchEmailTemplates, UpdateSeamsEmailTemplate } from '../../utils/seams';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Input, Radio, Stack, TextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const preventDefault = (event) => event.preventDefault();
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
}

const SeamsSettings = () => {
  const [templateType, setTemplateType] = useState('');
  const [templates, setTemplates] = useState([]);
  const [formData, setFormData] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [format, setFormat] = React.useState('');

  const [finalData, setFinalData] = React.useState({
    popupData: {
      id: "",
      body: "",
      from: "",
      reply: "",
      cc: "",
      bcc: "",
      subject: "",
      format: "",
      footer: false
    }
  });

  const handleOpen = (e, rowData) => {
   setFinalData((prevState) => ({
      ...prevState,
      popupData: {
        ...prevState.popupData,
        body: rowData.body,
        from: rowData.header.from,
        subject: rowData.header.subject,
        format: rowData.format,
        footer: rowData.addfooter,
        id: rowData.id
      }
    }));
    setOpen(true);
  }
  const handleSave = () => {
    setFormData([]);
    setOpen(false);
    setFormat('');
    UpdateSeamsEmailTemplate(finalData);
  }
  const handleClose = () => {
    setFormData([]);
    setOpen(false);
    setFormat('');
  }

  useEffect(() => {
    (async () => {
      const templates = await fetchEmailTemplates();
      setTemplates(templates);
    })();
  }, []);

  const templateTypeHandler = (e, type) => {
    e.preventDefault();
    setTemplateType(type);
  };

  const systemTemplates = [];
  const userTemplates = [];
  templates.filter((template) => {
    if (template.id.toString().match(/^\d+$/)) {
      userTemplates.push(template);
    } else {
      systemTemplates.push(template);
    }
  });
  const displayContent = () => {
    if (templateType === 'system') {
      return (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Email Template</TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>Description</TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}><Link href="#" underline="hover" color="#E64626" onClick={(e) => templateTypeHandler(e, '')}>
                    {'Return'}
                  </Link></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {systemTemplates.map((template) => (
                  <TableRow
                    key={template.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {template.id}
                    </TableCell>
                    <TableCell align="right">{template.description}</TableCell>
                    <TableCell align="right">
                      <Button onClick={(e) => { handleOpen(e, template) }} style={{ backgroundColor: "#E64626" }} size="small" variant="contained" endIcon={<EditIcon fontSize="small" />}>
                        Edit
                      </Button>
                      <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                      >
                        <DialogContent>
                          <Typography id="modal-modal-title" variant="h6" component="h2">
                            Sent to instructors who will have sites automatically created
                          </Typography>
                          <TextField style={{ width: '100%', marginBottom: '2%' }} value={finalData.popupData.from} onChange={(e) => {
                            setFinalData((prevState) => ({
                              ...prevState,
                              popupData: {
                                ...prevState.popupData,
                                from: e.target.value
                              }
                            }));
                          }} />
                          <TextField style={{ width: '100%', marginBottom: '2%' }} id="outlined-basic" label="Reply-To" variant="outlined" value={finalData.popupData.reply} onChange={(e) => {
                            setFinalData((prevState) => ({
                              ...prevState,
                              popupData: {
                                ...prevState.popupData,
                                reply: e.target.value
                              }
                            }));
                          }} />
                          <TextField style={{ width: '100%', marginBottom: '2%' }} id="outlined-basic" label="Cc:" variant="outlined" value={finalData.popupData.cc} onChange={(e) => {
                            setFinalData((prevState) => ({
                              ...prevState,
                              popupData: {
                                ...prevState.popupData,
                                cc: e.target.value
                              }
                            }));
                          }} />
                          <TextField style={{ width: '100%', marginBottom: '2%' }} id="outlined-basic" label="Bcc:" variant="outlined" value={finalData.popupData.bcc} onChange={(e) => {
                            setFinalData((prevState) => ({
                              ...prevState,
                              popupData: {
                                ...prevState.popupData,
                                bcc: e.target.value
                              }
                            }));
                          }} />
                          <TextField style={{ width: '100%', marginBottom: '2%' }} id="outlined-basic" label="Subject" variant="outlined" value={finalData.popupData.subject} onChange={(e) => {
                            setFinalData((prevState) => ({
                              ...prevState,
                              popupData: {
                                ...prevState.popupData,
                                subject: e.target.value
                              }
                            }));
                          }} />
                          <TextareaAutosize label="Message" style={{ resize: 'vertical', width: '100%', height: '50px', marginBottom: '2%' }} value={finalData.popupData.body}
                            onChange={(e) => {
                              setFinalData((prevState) => ({
                                ...prevState,
                                popupData: {
                                  ...prevState.popupData,
                                  body: e.target.value
                                }
                              }));
                            }}
                          ></TextareaAutosize>
                          <div style={{ display: 'flex' }}>
                            <label style={{ marginTop: '11px', marginRight: '5px' }}>Footer</label>
                            <FormGroup>
                              <FormControlLabel control={<Checkbox checked={finalData.popupData.footer ? true : false} />} label="Add standard footer" />
                            </FormGroup>
                          </div>
                          <div style={{ display: 'flex' }}>
                            <label style={{ marginTop: '11px', marginRight: '5px' }}>Format</label>
                            <FormGroup style={{ display: 'block' }}>
                              <FormControlLabel control={<Radio checked={(finalData.popupData.format === 'text') ? true : false} />} label="text" />
                              <FormControlLabel control={<Radio checked={(finalData.popupData.format === 'html') ? true : false} />} label="html" />
                            </FormGroup>
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <Button autoFocus onClick={handleSave}>
                            Save
                          </Button>
                          <Button autoFocus onClick={handleClose}>
                            Close
                          </Button>
                        </DialogActions>
                        {isDisplayed &&
                          <>
                            <Alert severity="success">This is a success alert — check it out!</Alert>
                          </>
                        }
                      </BootstrapDialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    } else if (templateType === 'user') {
      return (
        <>
          <h5>User Email Templates</h5>
          <table className='table'>
            <thead>
              <tr>
                <th scope='col'>Email Template</th>
                <th scope='col'>Description</th>
              </tr>
            </thead>
            <tbody>
              {userTemplates.map((template) => (
                <tr key={template.id}>
                  <td>{template.id}</td>
                  <td>{template.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <a href='#!' onClick={(e) => templateTypeHandler(e, '')}>
              Return
            </a>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h6" component="h6">
            Settings / Lookups
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'left',
              typography: 'body1',
              '& > :not(style) + :not(style)': {
                ml: 2,
              },
            }}
            onClick={preventDefault}
          >
            <Link href="#" underline="hover" color="#E64626" onClick={(e) => templateTypeHandler(e, 'system')}>
              {'Email Templates - System'}
            </Link>
            <Link href="#" underline="hover" color="#E64626" onClick={(e) => templateTypeHandler(e, 'user')}>
              {'Email Templates - User'}
            </Link>
            <Link href="#" underline="hover" color="#E64626">
              {'Screen Messages'}
            </Link>
          </Box>
        </>
      );
    }
  };

  return <div className='page'>{displayContent()}</div>;
};

export default SeamsSettings;
