import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

export default function CanvasTypeModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label='help' onClick={handleClickOpen}>
        <HelpIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='dialogText'>
          {'Types of Canvas sites explained'}
          <IconButton onClick={handleClose} className='dialogExit'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography className='dialogTextTitle'>
              Unit of study Canvas site
            </Typography>
            <Typography className='dialogSubText'>
              Is a curriculum resource for one or two cohorts for a single
              academic session. e.g. A Canvas site for Semester 1 MATH1000
            </Typography>
            <Typography className='dialogTextTitle'>
              Generic Canvas site
            </Typography>
            <Typography className='dialogSubText'>
              Is a curriculum resource which is not associated with an academic
              session. e.g. Practicum placements
            </Typography>
            <Typography className='dialogTextTitle'>
              eCommunity Canvas site
            </Typography>
            <Typography className='dialogSubText'>
              Used for non-teaching activities such as clubs, communities of
              practice etc
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
