
import { API } from 'aws-amplify';
import {
  role,
  emailTemplate,
  uos,
  session,
  user,
  faculty,
  enrollment,
  roleAdmin,
} from '../graphql/queries';
import { seamsEditMembership, seamsUpdateEmailTemplate } from '../graphql/mutations';

export const getSessionByYearAndPeriod = async ({year, period}) => {
  if (!year && !period)
    throw new Error("No session identifiers sent!")
  let res = await API.graphql({
    query: session,
    variables: {
      input: { year: year, period: period },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.session)
  return res
};

export const searchUos = async (input) => {
  if (typeof(input) === "string")
    input = input.trim().split(/\s+/)
  let res = await API.graphql({
    query: uos,
    variables: {
      input: { search: input },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.uos);
  return res.sort((a, b) => {
    if (a.session.year === b.session.year) {
      if (
        a.session.period.substring(1, 1) === b.session.period.substring(1, 1)
      ) {
        return b.session.period.length - a.session.period.length;
      } else {
        return (
          a.session.period.substring(1, 1) - b.session.period.substring(1, 1)
        );
      }
    } else {
      return (
        a.session.period.substring(1, 1) - b.session.period.substring(1, 1)
      );
    }
  });
};

export const searchUsers = async(input, limit=["staff"]) => {
  let res = await API.graphql({
    query: user,
    variables: {
      input: {
        search: input,
        include: limit
      },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.user);
  return res;
};

export const getUserByUnikey = async (unikey) => {
  let res = await API.graphql({
    query: user,
    variables: {
      input: {
        id: unikey,
      },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.user);
  return res[0].permission;
};

export const fetchRoles = async () => {
  let res = await API.graphql({
    query: role,
    variables: {
      input: {},
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.role);
  return res;
};

export const fetchUniqueRoles = async () => {
  const res = await fetchRoles();
  const roles = {};
  res.forEach((role) => {
    if (!roles[role.id]) {
      roles[role.id] = { id: role.id, description: role.description };
    }
  });
  console.log(roles);
  return Object.values(roles);
};

export const fetchEmailTemplates = async () => {
  let res = await API.graphql({
    query: emailTemplate,
    variables: {
      input: {},
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.emailTemplate);
  return res;
};

export const getUoS = async (id) => {
  // id defined by code-year-period-deliveryMode
  let res = await API.graphql({
    query: uos,
    variables: {
      input: {
        id: id,
        include: ["history"]
      }
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.uos);
  return res;
};

export const getFaculty = async (fac) => {
  // 4 char id
  let res = await API.graphql({
    query: faculty,
    variables: {
      input: { id: fac },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  return JSON.parse(res.data.faculty);
};

// Get all faculties
export const fetchFaculties = async () => {
  const res = await API.graphql({
    query: faculty,
    variables: { input: { id: null, search: null } },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  return JSON.parse(res.data.faculty);
};

export const getSiteEnrollment = async (
  course,
  types = ['instructor', 'designer', 'teaching_assistant', 'auditor']
) => {
  // course is the sis_course_id in canvas
  let res = await API.graphql({
    query: enrollment,
    variables: {
      input: {
        id: course,
        type: types
      }
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.enrollment);
  return res;
};

export const updateGroupRole = async (action, unikey, roleId, group) => {
  let res = await API.graphql({
    query: seamsEditMembership,
    variables: {
      post: {
        action: action,
        unikeys: unikey,
        roles: [
          {
            roleId,
            group,
          },
        ],
      },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  // console.log(res);
  res = JSON.parse(res.data.seamsEditMembership);
  return res;
};

export const getUsersByGroupId = async (groupId) => {
  let res = await API.graphql({
    query: role,
    variables: {
      input: {
        groups: groupId,
      },
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  const result = {};
  res = JSON.parse(res.data.role);
  res.forEach((role) => {
    role.members.forEach((member) => {
      if (result[member.unikey]) {
        result[member.unikey].push(role.id);
      } else {
        result[member.unikey] = [role.id];
      }
    });
  });
  return result;
};

export const getTopAndFacultyGroupRoles = async () => {
  let res = await API.graphql({
    query: roleAdmin,
    variables: { input: {} },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });

  const topGroupRoles = [];
  const facultyGroupRoles = [];
  JSON.parse(res.data.roleAdmin).forEach((role) => {
    if (role.type === 'eam') {
      topGroupRoles.push({
        name: role.id,
        label: role.title,
        order: +role.order,
      });
    } else if (role.type === 'faculty') {
      facultyGroupRoles.push({
        name: role.id,
        label: role.title,
        order: +role.order,
      });
    }
  });

  topGroupRoles.sort((a, b) => a.order - b.order);
  facultyGroupRoles.sort((a, b) => a.order - b.order);
  return [topGroupRoles, facultyGroupRoles];
};

export const UpdateSeamsEmailTemplate = async (data) => {
  let res = await API.graphql({
    query: seamsUpdateEmailTemplate,
    variables: {
      post: {
        id: data.popupData.id,
        body: data.popupData.body,
        addFooter: data.popupData.addFooter,
        format: data.popupData.format,
        header: {
          from: data.popupData.from,
          replyTo: data.popupData.reply,
          cc: data.popupData.cc,
          bcc: data.popupData.bcc,
          subject: data.popupData.subject
        }
      },

    },
    authMode: 'AMAZON_COGNITO_USER_POOLS',
  });
  res = JSON.parse(res.data.seamsUpdateEmailTemplate)
  return res;
};
