import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import createSiteReducer from './reducers/createSiteReducer';

const store = configureStore({
  reducer: {
    auth: authReducer,
    createSite: createSiteReducer,
  },
});

export default store;
