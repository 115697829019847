import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import DoneIcon from '@mui/icons-material/Done';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

export default function StaffRoleModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      Role
      <IconButton aria-label='help' onClick={handleClickOpen}>
        <HelpIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xl'>
        <DialogTitle className='dialogText'>
          {'Canvas site roles and permissions'}
          <IconButton onClick={handleClose} className='dialogExit'>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid item className='roleBox'>
                <Typography className='roleTitle'>
                  Extra Coordinator / Primary Learning Designer
                </Typography>
                <div className='roleDescriptionDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create discussion forums
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create all Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Edit Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Delete Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Access to Gradebook
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Add staff
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item className='roleBox'>
                <Typography className='roleTitle'>Instructor</Typography>

                <div className='roleDescriptionDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create discussion forums
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create all Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Edit Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Delete Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Access to Gradebook
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider className='roleDivider' />
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Add staff
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='roleBox'>
                <Typography className='roleTitle'>Learning Designer</Typography>
                <div className='roleDescriptionDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create discussion forums
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create all Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Edit Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Delete Canvas content
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider className='roleDivider' />
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Access to Gradebook
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Add staff
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='roleBox'>
                <Typography className='roleTitle'>
                  Teaching Assistant
                </Typography>
                <div className='roleDescriptionDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Create discussion forums
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        Access to Gradebook
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider className='roleDivider' />
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Create Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Edit Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Delete Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Add staff
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className='roleBox'>
                <Typography className='roleTitle'>Observer</Typography>
                <div className='roleDescriptionDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <DoneIcon sx={{ mr: 1 }} />
                    </Grid>
                    <Grid item>
                      <Typography className='roleDescription'>
                        View Canvas Site
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <Divider className='roleDivider' />
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Create Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Edit Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Delete Canvas content
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction='row' alignItems='center'>
                  <Grid item>
                    <CloseIcon sx={{ mr: 1 }} />
                  </Grid>
                  <Grid item>
                    <Typography className='roleDescription'>
                      Add staff
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
