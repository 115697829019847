import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const Step5 = () => {
  const dispatch = useDispatch();
  const {sisCourseId, copyContent, selectedStaff, startTime, endTime, selectedUoS, uoSSearchResults} = useSelector((state) => state.createSite);

  const uoS = uoSSearchResults.find((uos) => uos.id === selectedUoS);
  const name = uoS.name;
  const uoSCode = uoS.alpha + uoS.digit;

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container>
        <Grid item md={12} lg={12} xl={12}>
          <Grid container direction='row' alignItems='center'>
            <Grid item md={6} lg={6} xl={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant='h3' className='confirmH3'>
                  New Canvas site confirmation
                </Typography>
                <Divider className='confirmDivider' />
                <div className='confirmDiv'>
                  <Typography variant='h4' className='confirmTitle'>
                    Canvas site name
                  </Typography>
                  <Typography variant='h5' className='confirmInformation'>
                    {name}
                  </Typography>
                </div>
                <div className='confirmDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item md={11} lg={11} xl={11}>
                      <Typography variant='h4' className='confirmTitle'>
                        Unit of Study added to Canvas Site
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <IconButton aria-label='first page'>
                        <EditIcon className='confirmEditIcon' />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider className='informationDivider' />
                  <Typography variant='h5' className='confirmInformation'>
                    {uoSCode}
                  </Typography>
                </div>
                <div className='confirmDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item md={11} lg={11} xl={11}>
                      <Typography variant='h4' className='confirmTitle'>
                        List of staff added to Canvas site
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <IconButton aria-label='first page'>
                        <EditIcon className='confirmEditIcon' />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider className='informationDivider' />
                  <Typography variant='h5' className='confirmInformation'>
                    {selectedStaff.length} staff added
                  </Typography>
                </div>
                <div className='confirmDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item md={11} lg={11} xl={11}>
                      <Typography variant='h4' className='confirmTitle'>
                        Canvas site content copied from
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <IconButton aria-label='first page'>
                        <EditIcon className='confirmEditIcon' />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider className='informationDivider' />
                  <Typography variant='h5' className='confirmInformation'>
                    {sisCourseId ? sisCourseId : copyContent.replace(/-/g, ' ')}
                  </Typography>
                </div>
                <div className='confirmDiv'>
                  <Grid container direction='row' alignItems='center'>
                    <Grid item md={11} lg={11} xl={11}>
                      <Typography variant='h4' className='confirmDate'>
                        Student Access Date
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1} xl={1}>
                      <IconButton aria-label='first page'>
                        <EditIcon className='confirmEditIcon' />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider className='informationDivider' />
                  <Grid container direction='row' alignItems='center'>
                    <Grid item md={6} lg={6} xl={6}>
                      <Typography variant='h4' className='confirmTitle'>
                        Start date - site goes live if activated
                      </Typography>
                      <Typography variant='h5' className='confirmInformation'>
                        {(new Date(startTime)).toLocaleDateString('en-AU', options)}
                      </Typography>
                    </Grid>
                    <Grid item md={6} lg={6} xl={6}>
                      <Typography variant='h4' className='confirmTitle'>
                        End date - site becomes view-only for students
                      </Typography>
                      <Typography variant='h5' className='confirmInformation'>
                        {(new Date(endTime)).toLocaleDateString('en-AU', options)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step5;
