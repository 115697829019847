import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createUoSSite } from '../../../../store/actions/createSiteActions';
import { getFaculty } from "../../../../utils/seams";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

function getSteps() {
  return [
    'Add unit of study',
    'Add content',
    'Add staff',
    'Review Dates',
    'Confirm',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    case 3:
      return <Step4 />;
    case 4:
      return <Step5 />;
    default:
      return 'Unknown step';
  }
}

export default function UoSSite() {
  const dispatch = useDispatch();
  // const selectedUoS = useSelector((state) => state.createSite.selectedUoS);
  // const selectedStaff = useSelector((state) => state.createSite.selectedStaff);
  // const sisCourseId = useSelector((state) => state.createSite.sisCourseId);
  // const startTime = useSelector((state) => state.createSite.startTime);
  // const endTime = useSelector((state) => state.createSite.endTime);

  const { type, uos, users, copyFrom, startTime, endTime, copyContent, createSiteLog } = useSelector(
    (s) => {
      const map = { 'units-of-study-site': 'uos' }
      const result = {
        type: map[s.createSite.siteType],
        uos: s.createSite.selectedUoSObject,
        copyFrom: s.createSite.prevUoSSiteObject,
        startTime: s.createSite.startTime,
        endTime: s.createSite.endTime,
        users: s.createSite.staffList.filter(ss=>s.createSite.selectedStaff.indexOf(ss.unikey) !== -1),
        copyContent: s.createSite.copyContent,
        createSiteLog: s.createSite.createSiteLog
      };
      if (s.createSite.createSiteLog.length) {
        clearInterval(processingInterval)
      }
      console.log('Create Site input', result);
      return result;
    }
  );
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [error, setError] = React.useState(null)
  const [processing, setProcessing] = React.useState('.')
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 2;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  }
  const stepConditionsMet = () => {
    setError(null)
    if (activeStep === 0) {
      if (uos) return true
      throw new Error("You must select a Unit of Study to create a site around!")
    } else if (activeStep === 1) {
      if ((copyContent === "copy-prev-site" && copyFrom) || (copyContent && copyContent !== "copy-prev-site"))
        return true
      throw new Error("Select basis Canvas site is to be created!")
    } else if (activeStep === 2) {
    } else if (activeStep === 3) {
    } else if (activeStep === 4) {
    }
    return true
  }
  let count = 0, processingInterval
  const handleNext = async() => {
    try{
      if (stepConditionsMet()) {
        if (activeStep === steps.length - 1) {
          let basis = copyFrom
          if (copyContent === "faculty-template" && copyFrom === null) {
            let res = await getFaculty(uos.department.faculty)
            basis = {
              name: res[0].templates[0].desc,
              canvas: {
                id: res[0].templates[0].canvasId,
                sisId: res[0].templates[0].sis_course_id,
              }
            }
          }
          processingInterval = setInterval(
              ()=>{
                const tmp = Array.from({length: (count++)%5+1}, ()=>".").join("")
                setProcessing(tmp)
              }, 300
          )
          dispatch(createUoSSite(type, uos, users, basis, startTime, endTime));
        }
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    }catch(e){
      setError(e.message)
      console.error(e.message)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  return (
    <Container className='mainContainer' maxWidth='lg'>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 3 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant='caption'>Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {error ?
            <div style={{color: "red"}} className={"error"}>{error}</div> :
            null
        }
        {activeStep === steps.length ? (
          <div>
            <Grid container>
              <Grid item md={12} lg={12} xl={12}>
                <Grid container direction='row' alignItems='center'>
                  <Grid item md={12} lg={12} xl={12}>
                    <Typography variant='h3' className='tabH3'>
                      {createSiteLog.length ?
                          createSiteLog.map((l,i)=><p key={i} style={{margin: "1em 0 1em 0"}}>{l}</p>) :
                          `Processing${processing}`
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button sx={{ mt: 3 }} variant='contained' component={Link} to='/'>
              Back to Dashboard
            </Button>
          </div>
        ) : (
          <div>
            <Typography>{getStepContent(activeStep)}</Typography>
            <Box sx={{ mt: 3 }}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <div style={{ float: 'right' }}>
                {isStepOptional(activeStep) && (
                  <Button
                    variant='outlined'
                    onClick={handleSkip}
                    sx={{ mr: 2 }}
                  >
                    I'll add staff later
                  </Button>
                )}

                <Button variant='contained' onClick={handleNext}>
                  {activeStep === steps.length - 1
                    ? 'I confirm these details are correct, create this site'
                    : 'Next'}
                </Button>
              </div>
            </Box>
          </div>
        )}
      </div>
    </Container>
  );
}
