import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Navbar from '../components/layout/Navbar';
import Home from '../pages/Home';
import Login from '../pages/Login';
import SeamsUserGroups from '../pages/SeamsUserGroups';
import SeamsGroupUsers from '../pages/SeamsGroupUsers';
import Dashboard from '../pages/Dashboard';
import ManageSite from '../pages/ManageSite';
import NotFound from '../pages/NotFound';
import CreateSite from '../components/canvas/CreateSite';
import CreateUoSSite from '../components/canvas/create-site/create-uos-site/UoSSite.js';
import Faculties from '../components/sits/Faculties';
import SeamsRoles from '../components/seams/SeamsRoles';
import SeamsMembership from '../components/seams/SeamsMembership';
import SeamsSettings from '../components/seams/SeamsSettings';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<NotFound />} />

        <Route element={<ProtectedRoute />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/canvas'>
            <Route path='create-site' element={<CreateSite />} />
            <Route path='create-uos-site' element={<CreateUoSSite />} />
            <Route path='manage-site/:sectionid' element={<ManageSite />} />
          </Route>

          <Route path='/sits/faculties' element={<Faculties />} />
          <Route path='/seams'>
            <Route path='membership' element={<SeamsMembership />} />
            <Route
              path='membership/user/:userid'
              element={<SeamsUserGroups />}
            />
            <Route
              path='membership/group/:groupid'
              element={<SeamsGroupUsers />}
            />
            <Route path='roles' element={<SeamsRoles />} />
            <Route path='settings' element={<SeamsSettings />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
