import { API } from 'aws-amplify';
import { authActions } from '../reducers/authReducer';
import { auth } from '../../graphql/queries';

export const checkUser = () => async (dispatch) => {
  try {
    const res = await API.graphql({
      query: auth,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    const userDetails = JSON.parse(res.data.auth)[0];
    dispatch(authActions.login(userDetails));
  } catch (error) {
    console.log('User not logged in.');
  }
};
