import React, { Fragment, useState, useEffect } from 'react';
import { SORTED_ROLES } from '../../config/seams';
import { fetchUniqueRoles } from '../../utils/seams';

const SeamsRoles = () => {
  const [display, setDisplay] = useState('roles');
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      const roles = await fetchUniqueRoles();
      console.log(roles);
      setRoles(roles);
    })();
  }, []);

  const cancelHanlder = () => {
    document.body.click();
  };

  const deleteRoleHanlder = async (id) => {
    document.body.click();
    console.log('Deleting', id);
    setRoles(roles.filter((role) => role.ROLEID !== id));
  };

  const deletePopover = (id) => <div>DELET</div>;

  const displayRoles = (
    <Fragment>
      <h5>Roles</h5>
      {roles &&
        roles.map((role) => (
          <p key={role.id}>
            {role.id} {role.description}
          </p>
        ))}
    </Fragment>
  );

  const displayPermissions = (
    <Fragment>
      {/* <h5>Roles - Permissions</h5>
      <table className='table table-bordered'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>Role</th>
            <th scope='col'>Group Type</th>
            <th scope='col'>EAM Admin</th>
            <th scope='col'>Non-Students</th>
            <th scope='col'>Sections</th>
            <th scope='col'>SIS data</th>
            <th scope='col'>Cat/Cou/Ter</th>
            <th scope='col'>Reports</th>
            <th scope='col'>Eb CE6</th>
            <th scope='col'>CAL</th>
            <th scope='col'>Action</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => (
            <tr key={role.ROLEID}>
              <td>{role.ROLEID}</td>
              <td>{role.GROUPTYPE}</td>
              <td>{role.PERMISSIONS.eam_admin}</td>
              <td>{role.PERMISSIONS.non_students}</td>
              <td>{role.PERMISSIONS.sections}</td>
              <td>{role.PERMISSIONS.sis_data}</td>
              <td>{role.PERMISSIONS.cat_cou_ter}</td>
              <td>{role.PERMISSIONS.reports}</td>
              <td>{role.PERMISSIONS.eb_ce6}</td>
              <td>{role.PERMISSIONS.cal}</td>
              <td>
                {role.ROLEID !== 'eamadm' && (
                  <button className='btn btn-outline-primary btn-sm'>
                    <Pen />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      <button
        className='btn btn-outline-secondary'
        onClick={() => setDisplay('roles')}
      >
        Roles
      </button>
    </Fragment>
  );

  return (
    <div className='page'>
      {display === 'roles' ? displayRoles : displayPermissions}
    </div>
  );
};

export default SeamsRoles;
