import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Radio from '@mui/material/Radio';

const rows = [
  {
    id: 1,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 2,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 3,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 4,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 5,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 6,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
  {
    id: 7,
    unitOfStudyName: 'MATH1000: Course Name',
    year: 'Obrien',
    session: '2022',
    deliveryMode: 'ND',
    students: '123',
  },
];
let radioChecked = [rows[0].id];

const columns = [
  {
    field: 'radiobutton',
    headerName: '',
    width: 60,
    sortable: false,
    renderCell: (params) => (
      <Radio checked={radioChecked[0] === params.id} value={params.id} />
    ),
  },
  {
    field: 'unitOfStudyName',
    headerName: 'Unit of Study / Course Name',
    width: 350,
    sortable: false,
  },
  {
    field: 'year',
    headerName: 'Year',
    width: 170,
    sortable: false,
  },
  {
    field: 'session',
    headerName: 'Session',
    width: 170,
    sortable: false,
  },
  {
    field: 'deliveryMode',
    headerName: 'Delivery Mode',
    width: 170,
    sortable: false,
  },
  {
    field: 'students',
    headerName: 'Students',
    width: 170,
    sortable: false,
  },
];

export default function App() {
  const [selectionModel, setSelectionModel] = React.useState(radioChecked);
  radioChecked = selectionModel;

  return (
    <div className='App'>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
      />
    </div>
  );
}
