/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const seamsCreateSite = /* GraphQL */ `
  mutation SeamsCreateSite($post: seamsSiteInput) {
    seamsCreateSite(post: $post)
  }
`;
export const seamsActivateSite = /* GraphQL */ `
  mutation SeamsActivateSite($post: seamsActivateSiteInput) {
    seamsActivateSite(post: $post)
  }
`;
export const seamsUpdateEnrollment = /* GraphQL */ `
  mutation SeamsUpdateEnrollment($post: seamsEnrolInput) {
    seamsUpdateEnrollment(post: $post)
  }
`;
export const seamsUpdateEmailTemplate = /* GraphQL */ `
  mutation SeamsUpdateEmailTemplate($post: seamsEmailInput) {
    seamsUpdateEmailTemplate(post: $post)
  }
`;
export const seamsEditCohort = /* GraphQL */ `
  mutation SeamsEditCohort($post: seamsCohortInput) {
    seamsEditCohort(post: $post)
  }
`;
export const seamsEditSession = /* GraphQL */ `
  mutation SeamsEditSession($post: seamsSessionInput) {
    seamsEditSession(post: $post)
  }
`;
export const seamsEditMembership = /* GraphQL */ `
  mutation SeamsEditMembership($post: seamsMembershipInput) {
    seamsEditMembership(post: $post)
  }
`;
export const seamsEditUser = /* GraphQL */ `
  mutation SeamsEditUser($post: seamsUserInput) {
    seamsEditUser(post: $post)
  }
`;
export const seamsEditRole = /* GraphQL */ `
  mutation SeamsEditRole($post: seamsRoleInput) {
    seamsEditRole(post: $post)
  }
`;
export const canvasRestCreateCourse = /* GraphQL */ `
  mutation CanvasRestCreateCourse($post: newCanvasCourseInput) {
    canvasRestCreateCourse(post: $post)
  }
`;
export const canvasRestAddEnrollment = /* GraphQL */ `
  mutation CanvasRestAddEnrollment(
    $courseId: CanvasObject!
    $post: newCanvasEnrollmentInput
  ) {
    canvasRestAddEnrollment(courseId: $courseId, post: $post)
  }
`;
export const canvasRestCreateUser = /* GraphQL */ `
  mutation CanvasRestCreateUser($post: newCanvasUser) {
    canvasRestCreateUser(post: $post)
  }
`;
export const canvasRestPublishCourse = /* GraphQL */ `
  mutation CanvasRestPublishCourse($course: CanvasObject, $publish: Boolean) {
    canvasRestPublishCourse(course: $course, publish: $publish)
  }
`;
