import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { useSelector } from 'react-redux';

import CanvasTypeModal from './modals/CanvasType.js';
import './CreateSite.css';

const CreateSite = () => {
  const siteType = useSelector((state) => state.createSite.siteType);
  return (
    <Container className='mainContainer' maxWidth='xl'>
      <Box>
        <Grid container sx={{ ml: '3rem', mr: '3rem' }}>
          <Grid item md={12} lg={12} xl={12}>
            <FormControl>
              <Grid container direction='row' alignItems='center'>
                <Grid item>
                  <Typography variant='h3' className='tabH3'>
                    How will your new Canvas site be used?
                  </Typography>
                </Grid>
                <Grid item>
                  <CanvasTypeModal />
                </Grid>
              </Grid>
              <RadioGroup
                aria-labelledby='how-will-your-new-canvas-site-be-used-group-label'
                name='radio-buttons-group'
              >
                <FormControlLabel
                  value='units-of-study-site'
                  control={<Radio />}
                  label='For units of study'
                  checked={siteType === 'units-of-study-site'}
                />
                <FormControlLabel
                  value='generic-site'
                  control={<Radio />}
                  label='As a generic site'
                  disabled
                />
                <FormControlLabel
                  value='community-site'
                  control={<Radio />}
                  label='As an e-community site'
                  disabled
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sx={{ mt: '1.5rem' }} md={12} lg={12} xl={12}>
            <Button
              size='large'
              variant='outlined'
              className='questionButton'
              component={Link}
              to='/'
            >
              Back
            </Button>
            <Button
              size='large'
              variant='contained'
              className='questionButton'
              component={Link}
              to='/canvas/create-uos-site'
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default CreateSite;
